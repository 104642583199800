import React from 'react'
import PropTypes from 'prop-types'
import { Parallax, Background } from 'react-parallax'
import {
  Wrapper,
  Image,
  Video,
  VideoCurtain,
  Phones,
  Clouds,
} from './styles.js'

import HorWrap from '@atoms/HorWrap'
import OrangeHeader from '@atoms/OrangeHeader'
import Logo from '@layout/worksBlocks/Froodie/Logo'

import HeroPhoto from '@images/works/froodie/hero-bg.png'
import HeroPhone1 from '@images/works/froodie/hero-phone1.png'
import HeroPhone2 from '@images/works/froodie/hero-phone2.png'
import Cloud1 from '@images/works/froodie/hero-cloud1.png'
import Cloud2 from '@images/works/froodie/hero-cloud2.png'

const Hero = ({ active, path }) => {
  return (
    <Wrapper active={active}>
      <Video active={true} path={path}>
        <div>
          <Parallax strength={300}>
            <Background className="custom-bg">
              <Image src={HeroPhoto} active={active} />
            </Background>
          </Parallax>
        </div>
        <Phones active={active}>
          <img src={HeroPhone2} />
          <img src={HeroPhone1} />
        </Phones>
        <Clouds active={active}>
          <img src={Cloud1} />
          <img src={Cloud2} />
        </Clouds>
        <VideoCurtain active={active}>
          <div></div>
          <div></div>
          <div></div>
        </VideoCurtain>
      </Video>
      <HorWrap>
        <Logo active={active} />
      </HorWrap>
    </Wrapper>
  )
}

Hero.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Hero.defaultProps = {
  active: false,
  children: null,
}

export default Hero
