import styled, { css } from 'styled-components'
import BgImage from '@images/works/jayne/gradient-bg.png'
import BgImageFlipped from '@images/works/jayne/gradient-bg-flipped.png'
import BgImage2 from '@images/works/jayne/gradient-bg2.png'
import BgImage2Flipped from '@images/works/jayne/gradient-bg2-flipped.png'

export const Wrapper = styled.section`
  margin: 40px 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${({ white }) => (white ? 'white' : 'black')};

  > p {
    margin: 20px 0 60px;
    width: 100%;
    max-width: 900px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.66;
    color: ${({ white }) => (white ? 'white' : 'black')};
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, 20%, 0);
            opacity: 0;
          `}

    &:nth-child(1) {
      transition-delay: 0.3s;
    }

    &:nth-child(2) {
      transition-delay: 0.5s;
    }

    &:nth-child(3) {
      transition-delay: 0.7s;
    }

    &:nth-child(4) {
      transition-delay: 0.9s;
    }
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
    > p {
      width: ${({ white }) => (white ? '100%' : '66%')};
    }
  }
`

export const CustomHeader = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  font-size: 28px;
  font-weight: 900;

  > span {
    overflow: hidden;
    position: relative;
    display: inline-block;

    > span {
      position: relative;
      display: inline-block;
      padding-right: 5px;
      transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
      transition: all 0.8s 0.2s ease;
    }

    > span:nth-child(2) {
      transition-delay: 0.4s;
    }

    > span:nth-child(3) {
      transition-delay: 0.6s;
    }

    > span:nth-child(4) {
      transition-delay: 0.8s;
    }

    > span:nth-child(5) {
      transition-delay: 1s;
    }

    > span:nth-child(6) {
      transition-delay: 1.2s;
    }

    > span:nth-child(7) {
      transition-delay: 1.4s;
    }

    > span:nth-child(8) {
      transition-delay: 1.6s;
    }

    > span:nth-child(9) {
      transition-delay: 1.8s;
    }

    > span:nth-child(10) {
      transition-delay: 2s;
    }
  }

  ${({ theme }) => theme.media.m} {
    font-size: 42px;

    > span span {
      padding-right: 9px;
    }
  }

  ${({ theme }) => theme.media.l} {
    font-size: 52px;

    > span span {
      padding-right: 12px;
    }
  }
`
