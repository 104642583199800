import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'

import Hero from '@layout/worksBlocks/Froodie/Hero'
import CarImage from '@layout/worksBlocks/Froodie/CarImage'
import TextSection from '@layout/worksBlocks/Froodie/TextSection'
import SlidingImages1 from '@layout/worksBlocks/Froodie/SlidingImages1/index.js'
import PhonesSection from '@layout/worksBlocks/Froodie/PhonesSection'
import SlidingImages2 from '@layout/worksBlocks/Froodie/SlidingImages2/index.js'
import Logos from '@layout/worksBlocks/Froodie/Logos'
import Typography from '@layout/worksBlocks/Froodie/Typography'

import HorWrap from '@atoms/HorWrap'
import Credits from '@blocks/Credits'
import Next from '@blocks/Next'
import Spacer from '@blocks/Spacer'
import ColorPallete from '@blocks/ColorPallete'

const ProjectFroodie = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsProjectFroodie
  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  return (
    <Main
      seo={pageData.seoMetaTags}
      enterColor={pageData.color.hex}
      transitionStatus={transitionStatus}
    >
      <Hero
        active={startPage && transitionStatus === 'entered'}
        path="froodie"
      />
      <Credits data={pageData.credits[0]} />
      <CarImage />
      <HorWrap>
        <TextSection data={pageData.text1} />
      </HorWrap>
      <SlidingImages1 />
      <PhonesSection
        data={{
          text1: pageData.text2,
          text2: pageData.text3,
        }}
      />
      <SlidingImages2 />
      <HorWrap>
        <TextSection data={pageData.brandingText} />
      </HorWrap>
      <Logos />
      <Spacer size="s" />
      <ColorPallete data={pageData.colorPalette[0].colors} />
      <Spacer size="s" />
      <Typography />
      <Next data={pageData.nextProject} />
    </Main>
  )
}

export const query = graphql`
  query ProjectFroodieQuery {
    datoCmsProjectFroodie {
      slug
      name
      seoMetaTags {
        tags
      }
      color {
        hex
      }
      credits {
        id
        header
        description
        credits
      }
      colorPalette {
        id
        colors {
          name
          rgb
          cmyk
          color {
            hex
          }
          textColor {
            hex
          }
        }
      }
      text1
      text2
      text3
      brandingText
      nextProject {
        ... on DatoCmsProject {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectSpotify {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectJayne {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectOracle {
          id
          name
          slug
          color {
            hex
          }
        }
      }
    }
  }
`

export default ProjectFroodie
