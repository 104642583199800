import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import { Wrapper } from './styles.js'

import imageBg from '@images/works/froodie/hero-bg.png'
import imageCar from '@images/works/froodie/image-car.png'
import imageCloud from '@images/works/froodie/image-cloud1.png'

const CarImage = () => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper bg={imageBg} active={sectionInView}>
        <img src={imageCar} />
        <img src={imageCloud} />
        <img src={imageCloud} />
        <img src={imageCloud} />
      </Wrapper>
    </InView>
  )
}

export default CarImage
