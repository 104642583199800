import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import { Wrapper, Line1, Line2, Line3, Line4, Line5 } from './styles.js'

import phone1img from '@images/works/froodie/phones2/1.png'
import phone2img from '@images/works/froodie/phones2/2.png'
import phone3img from '@images/works/froodie/phones2/3.png'
import phone4img from '@images/works/froodie/phones2/4.png'
import phone5img from '@images/works/froodie/phones2/5.png'
import phone6img from '@images/works/froodie/phones2/6.png'
import phone7img from '@images/works/froodie/phones2/7.png'
import phone8img from '@images/works/froodie/phones2/8.png'
import phone9img from '@images/works/froodie/phones2/8.png'

const SlidingImages2 = () => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0}
    >
      <Wrapper active={sectionInView}>
        <Line1 active={sectionInView}>
          <img src={phone1img} />
        </Line1>
        <Line2 active={sectionInView}>
          <img src={phone2img} />
          <img src={phone3img} />
        </Line2>
        <Line3 active={sectionInView}>
          <img src={phone4img} />
          <img src={phone5img} />
          <img src={phone6img} />
        </Line3>
        <Line4 active={sectionInView}>
          <img src={phone7img} />
          <img src={phone8img} />
        </Line4>
        <Line5 active={sectionInView}>
          <img src={phone9img} />
        </Line5>
      </Wrapper>
    </InView>
  )
}

export default SlidingImages2
