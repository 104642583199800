import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import { Wrapper, PhonesText } from './styles.js'

import TextSection from '@layout/worksBlocks/Froodie/TextSection/index.js'
import HorWrap from '@atoms/HorWrap'

import mobileBg from '@images/works/froodie/phones-bg-mobile.jpg'
import desktopBg from '@images/works/froodie/phones-bg-desktop.jpg'
import imageCloud from '@images/works/froodie/image-cloud1.png'
import phone11 from '@images/works/froodie/phones1/1.png'

const PhonesSection = ({ data }) => {
  const [section2InView, setSection2InView] = useState(false)
  const [section3InView, setSection3InView] = useState(false)

  return (
    <Wrapper mobileBg={mobileBg} desktopBg={desktopBg} active={section2InView}>
      <HorWrap>
        <InView
          onChange={(inView, entry) => inView && setSection2InView(true)}
          threshold={0.25}
        >
          <PhonesText active={section2InView}>
            <TextSection data={data.text1} white />
            <div>
              <img src={phone11} />
            </div>
            <div>
              <img src={imageCloud} />
              <img src={imageCloud} />
              <img src={imageCloud} />
              <img src={imageCloud} />
            </div>
          </PhonesText>
        </InView>
        <InView
          onChange={(inView, entry) => inView && setSection3InView(true)}
          threshold={0.25}
        >
          <PhonesText active={section3InView} second>
            <TextSection data={data.text2} white />
            <div>
              <img src={phone11} />
            </div>
            <div>
              <img src={imageCloud} />
              <img src={imageCloud} />
              <img src={imageCloud} />
            </div>
          </PhonesText>
        </InView>
      </HorWrap>
    </Wrapper>
  )
}

export default PhonesSection
