import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1;
  position: relative;
  left: -10%;
  display: grid;
  width: 120%;
  grid-template-columns: repeat(5, 1fr);
  gap: 1%;
  margin: 0 0 -30%;
  transform: rotate(37deg);
  transform-origin: center center;

  > div {
    position: relative;
    width: 100%;
  }

  img {
    display: block;
    margin: 0 0 15px;
    width: 100%;
    transition: all 0.8s ease;
  }

  > div:nth-child(1) img,
  > div:nth-child(3) img,
  > div:nth-child(5) img {
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, -20%, 0);
            opacity: 0;
          `}
  }

  > div:nth-child(2) img,
  > div:nth-child(4) img,
  > div:nth-child(6) img {
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, -20%, 0);
            opacity: 0;
          `}
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
    margin: -10% 0 -30%;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`

export const Line1 = styled.div`
  top: 35%;

  img:nth-child(1) {
    transition-delay: 0.6s;
  }
`

export const Line2 = styled.div`
  top: 22%;

  img:nth-child(1) {
    transition-delay: 0.8s;
  }

  img:nth-child(2) {
    transition-delay: 1s;
  }

  img:nth-child(3) {
    transition-delay: 1.2s;
  }
`

export const Line3 = styled.div`
  top: 0%;

  img:nth-child(1) {
    transition-delay: 1s;
  }

  img:nth-child(2) {
    transition-delay: 1.2s;
  }

  img:nth-child(3) {
    transition-delay: 1.4s;
  }
`

export const Line4 = styled.div`
  z-index: 5;
  top: 22%;

  img:nth-child(1) {
    transition-delay: 1.4s;
  }

  img:nth-child(2) {
    transition-delay: 1.6s;
  }
`

export const Line5 = styled.div`
  top: 35%;

  img:nth-child(1) {
    transition-delay: 2s;
  }
`
