import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 100px 0;
  width: 100%;
  background: center center url(${({ mobileBg }) => mobileBg}) no-repeat;
  background-size: cover;
  color: white;

  img {
    transition: all 0.6s ease;
  }

  ${({ theme }) => theme.media.m} {
    padding: 120px 0;
  }

  ${({ theme }) => theme.media.l} {
    padding: 140px 0;
    background: center center url(${({ desktopBg }) => desktopBg}) no-repeat;
    background-size: cover;
  }
`

export const Header = styled.h2`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 40px;
  padding: 10px 0;
  font-size: 28px;
  font-weight: 900;

  > span {
    overflow: hidden;
    position: relative;
    display: inline-block;

    > span {
      position: relative;
      display: inline-block;
      padding-right: 5px;
      transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
      transition: all 0.8s ease;
    }
  }

  ${({ theme }) => theme.media.m} {
    font-size: 42px;

    > span span {
      padding-right: 9px;
    }
  }

  ${({ theme }) => theme.media.l} {
    font-size: 52px;

    > span span {
      padding-right: 12px;
    }
  }
`

export const Subheaders = styled.div`
  h3 {
    margin: 35px 0 1px;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.8s ${({ delay }) => (delay ? 0.2 + delay : '0.2')}s ease;
  }

  h4 {
    margin: 35px 0 20px;
    font-size: 12px;
    text-transform: uppercase;
    transition: all 0.8s ${({ delay }) => (delay ? 0.2 + delay : '0.2')}s ease;
  }

  h3 + h4 {
    margin: 0 0 20px;
  }

  h3,
  h4 {
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          `}
  }

  ${({ theme }) => theme.media.l} {
    h3 {
      margin: 45px 0 5px;
      font-size: 20px;
    }

    h4 {
      margin: 45px 0 35px;
      font-size: 16px;
    }

    h3 + h4 {
      margin: 0 0 35px;
    }
  }
`

export const Typo = styled.div`
  margin: 0 0 50px;

  img {
    width: 100%;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          `}

    &:nth-of-type(1) {
      transition-delay: 0.4s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.8s;
    }
    &:nth-of-type(3) {
      transition-delay: 1.2s;
    }
  }

  ${({ theme }) => theme.media.m} {
    margin: 0 0 75px;
  }

  ${({ theme }) => theme.media.l} {
    margin: 0 0 95px;
  }
`

export const System = styled.div`
  h3,
  h4 {
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 10%, 0);
          `}
  }

  ${({ theme }) => theme.media.l} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:nth-child(1) {
      width: 65%;
    }
  }
`

export const Buttons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24fr 24fr 27fr;
  gap: 20px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
      width: 100%;
    }
  }

  div:nth-child(3) img {
    width: 45%;
  }

  > div:nth-child(1) img {
    transition-delay: 0s;
  }

  > div:nth-child(2) img {
    transition-delay: 0.1s;
  }

  > div:nth-child(3) img {
    transition-delay: 0.2s;
  }

  > div:nth-child(4) img {
    transition-delay: 0.3s;
  }

  > div:nth-child(5) img {
    transition-delay: 0.4s;
  }

  > div:nth-child(6) img {
    transition-delay: 0.5s;
  }

  img {
    width: 100%;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          `}
  }
  ${({ theme }) => theme.media.l} {
    gap: 60px;
    width: 100%;
  }
`

function createCSS(start) {
  let styles = ''

  for (let i = 0; i < 24; i += 1) {
    styles += `
     img:nth-of-type(${i}) {
      transition-delay: ${start + i * 0.05}s;
    }
     `
  }

  return css`
    ${styles}
  `
}

function createCSS2(start) {
  let styles = ''

  for (let i = 0; i < 24; i += 1) {
    styles += `
    > div:nth-child(3) div:nth-child(${i}) img {
      transition-delay: ${start + i * 0.05}s;
    }
     `
  }

  return css`
    ${styles}
  `
}

export const Icons = styled.div`
  margin: 0 0 20px;
  width: 100%;

  > div {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  > div:nth-child(3) {
    margin: 10px 0 0;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 55%;
    }
  }

  img {
    width: 100%;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          `}
  }

  > div:nth-child(1) {
    ${createCSS(0)}
  }

  > div:nth-child(2) {
    ${createCSS(0.35)}
  }

  ${createCSS2(0.7)}

  ${({ theme }) => theme.media.l} {
    margin: 0 0 40px;
    width: 80%;

    > div:nth-child(3) {
      margin: 20px 0 0;
    }
  }
`

export const Cards = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  img {
    width: 100%;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          `}
  }

  img:nth-of-type(1) {
    transition-delay: 0.2s;
  }

  img:nth-of-type(2) {
    transition-delay: 0.4s;
  }

  img:nth-of-type(3) {
    transition-delay: 0.6s;
  }

  ${({ theme }) => theme.media.l} {
    width: 80%;
  }
`

export const Socials = styled.div`
  img {
    width: 10%;
    margin-right: 10px;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          `}
  }

  img:nth-of-type(1) {
    transition-delay: 0.2s;
  }

  img:nth-of-type(2) {
    transition-delay: 0.4s;
  }

  img:nth-of-type(3) {
    transition-delay: 0.6s;
  }

  ${({ theme }) => theme.media.m} {
    img {
      margin-right: 15px;
    }
  }

  ${({ theme }) => theme.media.l} {
    img {
      width: auto;
      margin-right: 20px;
    }
  }
`

export const Phone = styled.div`
  img {
    width: 50%;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 20%, 0);
          `}
  }

  ${({ theme }) => theme.media.l} {
    img {
      width: auto;
    }
  }
`
