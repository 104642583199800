import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 85%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 20%, 0);
          `}
  }

  img:nth-child(1) {
    position: static;
    transition-delay: 3s;
  }

  img:nth-child(2) {
    transition-delay: 3.2s;
  }

  img:nth-child(3) {
    transition-delay: 3.4s;
  }

  img:nth-child(4) {
    transition-delay: 3.6s;
  }

  img:nth-child(5) {
    transition-delay: 3.8s;
  }

  img:nth-child(6) {
    transition-delay: 4s;
  }

  img:nth-child(7) {
    transition-delay: 4.2s;
  }

  ${({ theme }) => theme.media.l} {
    width: 50%;
  }
`
