import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import ReactMarkdown from 'react-markdown'

import { Wrapper, Content, CustomHeader } from './styles.js'

const TextSection = ({ white, data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <Content active={sectionInView} white={white}>
          <ReactMarkdown
            components={{
              h1: node => (
                <CustomHeader active={sectionInView}>
                  <span>
                    {node.children[0].split(' ').map(word => (
                      <span>{word}</span>
                    ))}
                  </span>
                </CustomHeader>
              ),
              h2: node => (
                <CustomHeader active={sectionInView}>
                  <span>
                    {node.children[0].split(' ').map(word => (
                      <span>{word}</span>
                    ))}
                  </span>
                </CustomHeader>
              ),
              h3: node => (
                <CustomHeader active={sectionInView}>
                  <span>
                    {node.children[0].split(' ').map(word => (
                      <span>{word}</span>
                    ))}
                  </span>
                </CustomHeader>
              ),
            }}
          >
            {data}
          </ReactMarkdown>
        </Content>
      </Wrapper>
    </InView>
  )
}

export default TextSection
