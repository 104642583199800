import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 160px 0;
  width: 100%;
  background: center center url(${({ mobileBg }) => mobileBg}) no-repeat;
  background-size: cover;

  ${({ theme }) => theme.media.l} {
    padding: 340px 0 400px;
    background: center center url(${({ desktopBg }) => desktopBg}) no-repeat;
    background-size: cover;
  }
`

export const PhonesText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 0;

  > div:nth-child(2) {
    width: 80%;

    img {
      display: block;
      margin: 0 auto;
      width: 80%;
      transition: all 0.6s ease;
      ${({ active }) =>
        active
          ? css`
              opacity: 1;
              transform: translate3d(0, 0, 0);
            `
          : css`
              opacity: 0;
              transform: translate3d(0, 10%, 0);
            `}
    }
  }

  > div:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > img {
      position: absolute;
      transition: all 0.6s ease;
      ${({ active }) =>
        active
          ? css`
              transform: translate3d(-50%, -50%, 0) scale(1);
            `
          : css`
              transform: translate3d(-50%, -50%, 0) scale(0);
            `}
    }

    > img:nth-child(1) {
      transition-delay: 0.3s;
      ${({ second }) =>
        !second
          ? css`
              width: 10%;
              left: 10%;
              top: 3%;
            `
          : css`
              width: 20%;
              left: 50%;
              top: 8%;
            `}
    }

    > img:nth-child(2) {
      transition-delay: 0.6s;
      ${({ second }) =>
        !second
          ? css`
              width: 20%;
              left: 95%;
              top: -13%;
            `
          : css`
              width: 6%;
              left: 58%;
              bottom: -5%;
            `}
    }

    > img:nth-child(3) {
      transition-delay: 0.9s;
      ${({ second }) =>
        !second
          ? css`
              width: 6%;
              left: 98%;
              top: 5%;
            `
          : css`
              width: 15%;
              left: -20%;
              bottom: -10%;
            `}
    }

    > img:nth-child(4) {
      transition-delay: 1.8s;
      width: 6%;
      left: 48%;
      bottom: -10%;
    }
  }

  ${({ theme }) => theme.media.l} {
    flex-direction: row;
    justify-content: space-between;

    > div:nth-child(1),
    > div:nth-child(2) {
      width: 40%;

      img {
        margin: 0;
        width: 50%;
      }
    }

    ${({ second }) =>
      second &&
      css`
        img {
          margin-left: 20%;
        }
        > div:nth-child(1) {
          order: 2;
        }
      `}
  }
`
