import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 60px 0 100px;
  width: 100%;
  height: 400px;
  background: center center url(${({ bg }) => bg}) no-repeat;
  background-size: cover;

  img {
    position: absolute;
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(-50%, -50%, 0) scale(1);
          `
        : css`
            transform: translate3d(-50%, -50%, 0) scale(0);
          `}
  }

  img:nth-child(1) {
    top: 70%;
    left: 62%;
    width: 90%;
    transition-delay: 0.6s;
  }

  img:nth-child(2) {
    top: -2%;
    left: 18%;
    width: 30%;
    transition-delay: 0.8s;
  }

  img:nth-child(3) {
    bottom: 50%;
    right: 10%;
    width: 20%;
    transition-delay: 1s;
  }

  img:nth-child(4) {
    bottom: 40%;
    left: 15%;
    width: 12%;
    transition-delay: 1.2s;
  }

  ${({ theme }) => theme.media.m} {
    img:nth-child(1) {
      top: 75%;
      left: 62%;
      width: 60%;
    }
  }

  ${({ theme }) => theme.media.l} {
    margin: 100px 0 220px;
    height: 1000px;

    img:nth-child(1) {
      top: 80%;
      left: 60%;
      width: 70%;
    }

    img:nth-child(2) {
      top: -2%;
      left: 18%;
      width: 15%;
    }

    img:nth-child(3) {
      bottom: 18%;
      width: 10%;
    }
  }
`
