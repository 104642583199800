import React from 'react'

import { Wrapper } from './styles.js'

import image1 from '@images/works/froodie/logo1.png'
import image2 from '@images/works/froodie/logo2.png'
import image3 from '@images/works/froodie/logo3.png'
import image4 from '@images/works/froodie/logo4.png'
import image5 from '@images/works/froodie/logo5.png'
import image6 from '@images/works/froodie/logo6.png'
import image7 from '@images/works/froodie/logo7.png'

const Logo = ({ active }) => {
  return (
    <Wrapper active={active}>
      <img src={image1} />
      <img src={image2} />
      <img src={image3} />
      <img src={image4} />
      <img src={image5} />
      <img src={image6} />
      <img src={image7} />
    </Wrapper>
  )
}

export default Logo
