import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import {
  Wrapper,
  Header,
  Subheaders,
  Typo,
  System,
  Buttons,
  Icons,
  Cards,
  Socials,
  Phone,
} from './styles.js'

import HorWrap from '@atoms/HorWrap'

import mobileBg from '@images/works/froodie/typography-mobile.jpg'
import desktopBg from '@images/works/froodie/typography-desktop.jpg'

import typoImg1 from '@images/works/froodie/typography/typo1.png'
import typoImg2 from '@images/works/froodie/typography/typo2.png'
import typoImg3 from '@images/works/froodie/typography/typo3.png'

import buttonImg1 from '@images/works/froodie/typography/button1.png'
import buttonImg2 from '@images/works/froodie/typography/button2.png'
import buttonImg3 from '@images/works/froodie/typography/button3.png'
import buttonImg4 from '@images/works/froodie/typography/button4.png'
import buttonImg5 from '@images/works/froodie/typography/button5.png'
import buttonImg6 from '@images/works/froodie/typography/button6.png'
import buttonImg7 from '@images/works/froodie/typography/button7.png'

import iconImg1 from '@images/works/froodie/typography/icon1.png'
import iconImg2 from '@images/works/froodie/typography/icon2.png'
import iconImg3 from '@images/works/froodie/typography/icon3.png'
import iconImg4 from '@images/works/froodie/typography/icon4.png'
import iconImg5 from '@images/works/froodie/typography/icon5.png'
import iconImg6 from '@images/works/froodie/typography/icon6.png'
import iconImg7 from '@images/works/froodie/typography/icon7.png'
import iconImg8 from '@images/works/froodie/typography/icon8.png'
import iconImg15 from '@images/works/froodie/typography/icon15.png'
import iconImg16 from '@images/works/froodie/typography/icon16.png'
import iconImg17 from '@images/works/froodie/typography/icon17.png'
import iconImg18 from '@images/works/froodie/typography/icon18.png'
import iconImg19 from '@images/works/froodie/typography/icon19.png'
import iconImg20 from '@images/works/froodie/typography/icon20.png'
import iconImg21 from '@images/works/froodie/typography/icon21.png'

import cardImg1 from '@images/works/froodie/typography/card1.png'
import cardImg2 from '@images/works/froodie/typography/card2.png'
import cardImg3 from '@images/works/froodie/typography/card3.png'

import socialImg1 from '@images/works/froodie/typography/social1.png'
import socialImg2 from '@images/works/froodie/typography/social2.png'
import socialImg3 from '@images/works/froodie/typography/social3.png'

import phoneImg from '@images/works/froodie/typography/phone.png'

const Typography = ({ data }) => {
  const [section1InView, setSection1InView] = useState(false)
  const [section2InView, setSection2InView] = useState(false)
  const [section3InView, setSection3InView] = useState(false)
  const [section4InView, setSection4InView] = useState(false)
  const [section5InView, setSection5InView] = useState(false)
  const [section6InView, setSection6InView] = useState(false)

  return (
    <Wrapper mobileBg={mobileBg} desktopBg={desktopBg}>
      <HorWrap>
        <InView
          onChange={(inView, entry) => inView && setSection1InView(true)}
          threshold={0.25}
        >
          <Typo active={section1InView}>
            <Header active={section1InView}>
              <span>
                <span>Typography</span>
              </span>
            </Header>
            <Subheaders active={section1InView}>
              <h3>Primary Font</h3>
              <h4>Dico Code One Regular</h4>
            </Subheaders>
            <img src={typoImg1} />
            <Subheaders active={section1InView} delay={0.4}>
              <h4>Dico Code One Bold</h4>
            </Subheaders>
            <img src={typoImg2} />
            <Subheaders active={section1InView} delay={0.8}>
              <h3>Secondary Font</h3>
              <h4>Acumin Family</h4>
            </Subheaders>
            <img src={typoImg3} />
          </Typo>
        </InView>

        <System active={section2InView}>
          <div>
            <Header active={section2InView}>
              <span>
                <span>System</span>
              </span>
            </Header>
            <InView
              onChange={(inView, entry) => inView && setSection2InView(true)}
              threshold={0.25}
            >
              <Subheaders active={section2InView}>
                <h4>Buttons</h4>
              </Subheaders>
              <Buttons active={section2InView}>
                <div>
                  <img src={buttonImg1} />
                </div>
                <div>
                  <img src={buttonImg2} />
                </div>
                <div>
                  <img src={buttonImg3} />
                  <img src={buttonImg4} />
                </div>
                <div>
                  <img src={buttonImg5} />
                </div>
                <div>
                  <img src={buttonImg6} />
                </div>
                <div>
                  <img src={buttonImg7} />
                </div>
              </Buttons>
            </InView>
            <InView
              onChange={(inView, entry) => inView && setSection3InView(true)}
              threshold={0.25}
            >
              <Subheaders active={section3InView}>
                <h4>Icons</h4>
              </Subheaders>
              <Icons active={section3InView}>
                <div>
                  <img src={iconImg1} />
                  <img src={iconImg2} />
                  <img src={iconImg3} />
                  <img src={iconImg4} />
                  <img src={iconImg5} />
                  <img src={iconImg6} />
                  <img src={iconImg7} />
                </div>
                <div>
                  <img src={iconImg8} />
                  <img src={iconImg8} />
                  <img src={iconImg8} />
                  <img src={iconImg8} />
                  <img src={iconImg8} />
                  <img src={iconImg8} />
                  <img src={iconImg8} />
                </div>
                <div>
                  <div>
                    <img src={iconImg15} />
                  </div>

                  <div>
                    <img src={iconImg16} />
                  </div>
                  <div>
                    <img src={iconImg17} />
                  </div>
                  <div>
                    <img src={iconImg18} />
                  </div>
                  <div>
                    <img src={iconImg19} />
                  </div>
                  <div>
                    <img src={iconImg20} />
                  </div>
                  <div>
                    <img src={iconImg21} />
                  </div>
                </div>
              </Icons>
            </InView>
            <InView
              onChange={(inView, entry) => inView && setSection4InView(true)}
              threshold={0.25}
            >
              <Cards active={section4InView}>
                <img src={cardImg1} />
                <img src={cardImg2} />
                <img src={cardImg3} />
              </Cards>
            </InView>
          </div>
          <div>
            <InView
              onChange={(inView, entry) => inView && setSection5InView(true)}
              threshold={0.25}
            >
              <Subheaders active={section5InView}>
                <h4>Social icons</h4>
              </Subheaders>
              <Socials active={section5InView}>
                <img src={socialImg1} />
                <img src={socialImg2} />
                <img src={socialImg3} />
              </Socials>
            </InView>
            <InView
              onChange={(inView, entry) => inView && setSection6InView(true)}
              threshold={0.25}
            >
              <Subheaders active={section6InView}>
                <h4>Map</h4>
              </Subheaders>
              <Phone active={section6InView}>
                <img src={phoneImg} />
              </Phone>
            </InView>
          </div>
        </System>
      </HorWrap>
    </Wrapper>
  )
}

export default Typography
